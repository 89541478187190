import React from "react"

export const Heading = ({ title, subtitle, center, ...other }) => (
  <div {...other}>
    {(title || subtitle) && (
      <>
        <h2
          className={`${
            center && `text-center`
          } sm:text-4xl font-headline mb-2 text-3xl font-bold`}
        >
          {title}
        </h2>
        <hr
          className={`${center && `mx-auto`} border-black w-24 mt-4 mb-5 border`}
        />
        <div
          className={`${
            center && `max-w-2xl mx-auto text-center`
          } mt-3 text-gray-500 sm:mt-4 prose`}
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
      </>
    )}
  </div>
)
