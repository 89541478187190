import React from "react"
import { Transition } from "@headlessui/react"

import Heart from "../../images/property/heart.inline.svg"
import Brochure from "../../images/property/brochure.inline.svg"
import Calendar from "../../images/property/calendar.inline.svg"
import Phone from "../../images/property/phone.inline.svg"

export const Modal = ({
  title,
  subtitle,
  close,
  show,
  onClose,
  small,
  center,
  icon = "heart",
  children,
}) => (
  <>
    <Transition show={show}>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 transition-opacity"
            onClick={() => onClose()}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6 ${
              small ? `sm:max-w-sm` : `sm:max-w-xl`
            }`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {title && (
              <div className={center ? `text-center` : `flex items-center`}>
                <div
                  className={`${
                    center ? `mx-auto mb-4` : `mr-4`
                  } bg-blue flex items-center justify-center w-12 h-12 rounded-full`}
                >
                  {
                    {
                      heart: <Heart className="w-6 h-6 text-gold" />,
                      phone: <Phone className="w-6 h-6 text-gold" />,
                      brochure: <Brochure className="w-6 h-6 text-gold" />,
                      calendar: <Calendar className="w-6 h-6 text-gold" />,
                    }[icon]
                  }
                </div>
                <h3
                  className="text-lg font-semibold leading-6 text-blue"
                  id="modal-headline"
                >
                  {title}
                </h3>
              </div>
            )}
            {subtitle && (
              <p
                className={`${
                  center ? `mt-1 text-center` : `mt-4`
                } text-gray-500`}
              >
                {subtitle}
              </p>
            )}
            {children && <section className="mt-4">{children}</section>}
            {close && (
              <div className="mt-5 sm:mt-6">
                <button
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white transition duration-200 border border-transparent rounded-md shadow-sm bg-black hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:text-sm"
                  onClick={() => onClose()}
                >
                  {close}
                </button>
              </div>
            )}
          </Transition.Child>
        </div>
      </div>
    </Transition>
  </>
)
