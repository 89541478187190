import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { linkResolver } from "../../utils/linkResolver"

export const Featured = ({ data, title, subtitle, image, linkDisabled }) => (
  <>
    <Link
      to={linkResolver(data)}
      className={linkDisabled && `pointer-events-none cursor-default`}
      disabled={linkDisabled}
    >
      <BackgroundImage
        Tag="section"
        className="bg-black hero"
        fluid={image}
        backgroundColor="#072837"
      >
        <div className="h-full overlay">
          <div className="container flex items-end h-full">
            <div className="pb-12">
              <h1 className="text-3xl font-bold text-white font-headline md:text-4xl">
                {title}
              </h1>
              <div className="mt-2">
                <p className="text-gray-300">
                  {subtitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Link>
  </>
)
