import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

export const Mapbox = ({ center, zoom, className }) => {
  const map_node = useRef(null)
  const map_ref = useRef(null)

  useEffect(() => {
    if (!(typeof window !== "undefined" && window)) return null

    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

    const map = new mapboxgl.Map({
      container: map_node.current,
      style: `mapbox://styles/ikeys/ckgz3sofn15fq19qlm2hh15sh?optimize=true`,
      center: center,
      zoom: zoom,
      attributionControl: false,
    })
    map_ref.current = map
    map_ref.current = map

    map.addControl(new mapboxgl.NavigationControl(), "top-right")
    map.scrollZoom.disable()

    map.on("load", () => {
      var el = document.createElement("div")
      el.className = "marker"

      new mapboxgl.Marker(el).setLngLat(center).addTo(map)
    })

    return () => {
      map.remove()
    }
  }, [center, zoom])

  return <div className={`mapbox ${className}`} ref={map_node}></div>
}

Mapbox.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  className: PropTypes.string,
}
