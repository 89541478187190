import React, {
  useReducer,
  useContext,
  useEffect,
  createContext,
  useState,
} from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Notification } from "../components/utils"
import { Locale } from "./Locale"

export const StoreContext = createContext()

const storeReducer = (state, action) => {
  if (!state.properties) {
    state.properties = []
  }

  switch (action.type) {
    case "toggle": {
      // Check if already exists
      let index = state.properties.findIndex(
        x => x.reference === action.property.reference
      )

      if (index === -1) {
        // Add property
        return {
          ...state,
          properties: state.properties.concat(action.property),
          action: "added",
        }
      } else {
        // Remove property
        state.properties.splice(index, 1)
        return { ...state, properties: state.properties, action: "removed" }
      }
    }
    case "reset": {
      // Remove all properties
      return { ...state, properties: [], action: "reset" }
    }
    case "change_currency": {
      // Change language
      return { ...state, currency: action.currency, action: "change_currency" }
    }
    default: {
      throw new Error(`Unhandled action type ${action.type}`)
    }
  }
}

const StoreProvider = ({ children }) => {
  let { locale } = useContext(Locale)

  const [showAdded, setShowAdded] = useState(false)
  const [showRemoved, setShowRemoved] = useState(false)
  const [showReset, setShowReset] = useState(false)

  const store = useStaticQuery(graphql`
    query StoreQuery {
      en: datoCmsSidebar(locale: { eq: "en" }) {
        ...Sidebar
      }
      fr: datoCmsSidebar(locale: { eq: "fr" }) {
        ...Sidebar
      }
      currencies: allDatoCmsCurrency {
        all: distinct(field: code)
      }
    }
  `)

  let localStore = () => {
    if (typeof window !== `undefined`) {
      let currency = "EUR"
      const localCurrency = window.localStorage.getItem("currency")

      if (store.currencies.all.includes(localCurrency)) {
        currency = localCurrency
      }

      let properties = []
      try {
        properties = JSON.parse(window.localStorage.getItem("properties"))
      } catch (error) {
        console.error(error)
      }

      return { currency, properties }
    }

    return { currency: "EUR", properties: [] }
  }

  const [state, dispatch] = useReducer(
    storeReducer,
    { currency: "EUR", properties: [] },
    localStore
  )

  useEffect(() => {
    localStorage.setItem(
      "properties",
      JSON.stringify(state.properties ? state.properties : [])
    )

    localStorage.setItem("currency", state.currency ? state.currency : "EUR")

    // Display notification
    setShowAdded(false)
    setShowReset(false)
    setShowRemoved(false)

    if (state.action === "added") {
      setShowAdded(true)
    } else if (state.action === "removed") {
      setShowRemoved(true)
    } else if (state.action === "reset") {
      setShowReset(true)
    }
  }, [state])

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
      <Notification
        title={store[locale].notificationSavedTitle}
        subtitle={store[locale].notificationSavedSubtitle}
        onClose={() => setShowAdded(false)}
        show={showAdded}
      />
      <Notification
        title={store[locale].notificationRemovedTitle}
        subtitle={store[locale].notificationRemovedSubtitle}
        onClose={() => setShowRemoved(false)}
        show={showRemoved}
      />
      <Notification
        title={store[locale].notificationResetTitle}
        subtitle={store[locale].notificationResetSubtitle}
        onClose={() => setShowReset(false)}
        show={showReset}
      />
    </StoreContext.Provider>
  )
}

const useStore = () => useContext(StoreContext)

export { StoreProvider, useStore }
