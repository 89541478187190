import React, { useState, useContext, createContext } from "react"

export const SidebarContext = createContext()

const SidebarProvider = props => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen: () => setIsSidebarOpen(!isSidebarOpen),
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  )
}

const useSidebar = () => useContext(SidebarContext)

export { SidebarProvider, useSidebar }
