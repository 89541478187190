const project = {
  en: "projects",
  fr: "projets",
}

export const linkResolver = (link, queryParameters = "") => {
  if (!link || !link.locale || !link.slug) return null

  if (link.model.apiKey === "category") {
    return `/${link.locale}/magazine/${link.slug}${queryParameters}`
  }

  if (link.model.apiKey === "post") {
    return `/${link.locale}/magazine/${link.slug}${queryParameters}`
  }

  if (link.model.apiKey === "project") {
    return `/${link.locale}/${project[link.locale]}/${
      link.slug
    }${queryParameters}`
  }

  return `/${link.locale}/${link.slug}${queryParameters}`
}
