import React, { useState, useEffect, useContext, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import fx from "money"

import { useStore, Locale } from "../../context"

export const Price = ({ price, hidePrice, currency, status = null }) => {
  let { locale } = useContext(Locale)
  const { state } = useStore()
  const [convertedPrice, setConvertedPrice] = useState()

  const data = useStaticQuery(graphql`
    query PriceQuery {
      currencies: allOpenExchangeRates {
        all: nodes {
          currency
          rate
        }
      }
      en: datoCmsSearch(locale: { eq: "en" }) {
        priceOnRequest
        perMonth
      }
      fr: datoCmsSearch(locale: { eq: "fr" }) {
        priceOnRequest
        perMonth
      }
    }
  `)

  const convert = useCallback(
    (price, from = "EUR") => {
      const convertedPrice = fx.convert(price, {
        from: currency,
        to: state.currency,
      })

      const formattedPrice = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: state.currency.toLowerCase(),
        minimumFractionDigits: 0,
      }).format(Math.round(convertedPrice / 100) * 100)

      return formattedPrice
    },
    [currency, locale, state.currency]
  )

  useEffect(() => {
    fx.base = "USD"
    data.currencies.all.forEach(item => (fx.rates[item.currency] = item.rate))
  }, [data.currencies])

  useEffect(() => {
    setConvertedPrice(convert(price, currency))
  }, [price, currency, state.currency, locale, convert])

  // Hide price if rented or sold
  if (status && (status.originalId === "34805497" || status.originalId === "34805527")) return <>{status.name}</>

  // Hide price if equal to zero
  if (price === 0 || hidePrice) return <>{data[locale].priceOnRequest}</>

  return <>{convertedPrice} {status.originalId === "6625335" && data[locale].perMonth}</>
}
