import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useStore, useSidebar, Locale } from "../../context"

import Heart from "../../images/property/heart.inline.svg"

export const Saved = ({ transparent, small }) => {
  let { locale } = useContext(Locale)

  const { state } = useStore()
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar()

  const header = useStaticQuery(graphql`
    query SavedQuery {
      en: datoCmsHeader(locale: { eq: "en" }) {
        saved
      }
      fr: datoCmsHeader(locale: { eq: "fr" }) {
        saved
      }
    }
  `)

  return (
    <button
      className={`${
        transparent && !isSidebarOpen
          ? `text-gray-200 hover:text-white`
          : `text-gray-500 hover:text-blue`
      } flex items-center transition duration-200 focus:outline-none`}
      onClick={() => setIsSidebarOpen()}
    >
      <Heart className="w-5 h-5" />
      <p
        className={`ml-4 flex items-center ${
          small ? `hidden` : `hidden lg:inline`
        }`}
      >
        {header[locale].saved}
        {state.properties && state.properties.length > 0 && (
          <span
            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-black text-white ml-3 relative"
            style={{ top: `-1px` }}
          >
            {state.properties.length}
          </span>
        )}
      </p>
    </button>
  )
}
