/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import { StoreProvider, SidebarProvider } from "./src/context"

import "./src/sass/tailwind.scss"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    <SidebarProvider>{element}</SidebarProvider>
  </StoreProvider>
)
