import React from "react"
import { Link } from "gatsby"
import { HomeIcon } from "@heroicons/react/solid"
import { HelmetDatoCms } from "gatsby-source-datocms"

export const Breadcrumbs = ({ pages, current }) => (
  <nav className="flex mb-10" aria-label="Breadcrumb">
    <HelmetDatoCms>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: pages
            .map((page, index) => ({
              "@type": "ListItem",
              position: index + 1,
              name: page.name,
              item: `https://ikeys-realty.com/${page.locale}/${page.link}`,
            }))
            .concat({
              "@type": "ListItem",
              position: pages.length + 1,
              name: current,
            }),
        })}
      </script>
    </HelmetDatoCms>
    <ol className="flex flex-wrap items-center space-x-2 lg:space-x-4">
      <li className="mb-3">
        <div>
          <Link
            to={`/${pages[0].locale}`}
            className="text-gray-400 hover:text-gray-500"
          >
            <HomeIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </div>
      </li>
      {pages.map(page => (
        <li className="mb-3" key={page.name}>
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-5 h-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <Link
              to={`/${page.locale}/${page.link}`}
              className="ml-2 text-sm font-medium text-gray-500 lg:ml-4 hover:text-gray-700"
              aria-current={page.current ? "page" : undefined}
            >
              {page.name}
            </Link>
          </div>
        </li>
      ))}
    </ol>
  </nav>
)
