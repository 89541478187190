exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-en-thank-you-jsx": () => import("./../../../src/pages/en/thank-you.jsx" /* webpackChunkName: "component---src-pages-en-thank-you-jsx" */),
  "component---src-pages-fr-404-jsx": () => import("./../../../src/pages/fr/404.jsx" /* webpackChunkName: "component---src-pages-fr-404-jsx" */),
  "component---src-pages-fr-thank-you-jsx": () => import("./../../../src/pages/fr/thank-you.jsx" /* webpackChunkName: "component---src-pages-fr-thank-you-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-category-index-jsx": () => import("./../../../src/templates/category/Index.jsx" /* webpackChunkName: "component---src-templates-category-index-jsx" */),
  "component---src-templates-home-index-jsx": () => import("./../../../src/templates/home/Index.jsx" /* webpackChunkName: "component---src-templates-home-index-jsx" */),
  "component---src-templates-landing-page-index-jsx": () => import("./../../../src/templates/landing-page/Index.jsx" /* webpackChunkName: "component---src-templates-landing-page-index-jsx" */),
  "component---src-templates-magazine-index-jsx": () => import("./../../../src/templates/magazine/Index.jsx" /* webpackChunkName: "component---src-templates-magazine-index-jsx" */),
  "component---src-templates-page-index-jsx": () => import("./../../../src/templates/page/Index.jsx" /* webpackChunkName: "component---src-templates-page-index-jsx" */),
  "component---src-templates-post-index-jsx": () => import("./../../../src/templates/post/Index.jsx" /* webpackChunkName: "component---src-templates-post-index-jsx" */),
  "component---src-templates-project-index-jsx": () => import("./../../../src/templates/project/Index.jsx" /* webpackChunkName: "component---src-templates-project-index-jsx" */),
  "component---src-templates-projects-index-jsx": () => import("./../../../src/templates/projects/Index.jsx" /* webpackChunkName: "component---src-templates-projects-index-jsx" */),
  "component---src-templates-property-index-jsx": () => import("./../../../src/templates/property/Index.jsx" /* webpackChunkName: "component---src-templates-property-index-jsx" */),
  "component---src-templates-search-index-jsx": () => import("./../../../src/templates/search/Index.jsx" /* webpackChunkName: "component---src-templates-search-index-jsx" */),
  "component---src-templates-sold-index-jsx": () => import("./../../../src/templates/sold/Index.jsx" /* webpackChunkName: "component---src-templates-sold-index-jsx" */)
}

